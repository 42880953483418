export const firebaseConfig = {
  apiKey: "AIzaSyDGFjV3KQfvT3QNXymW3NQmop5KgOWhXGo",
  authDomain: "plan-9-frame-by-frame.firebaseapp.com",
  databaseURL: "https://plan-9-frame-by-frame.firebaseio.com",
  projectId: "plan-9-frame-by-frame",
  storageBucket: "plan-9-frame-by-frame.appspot.com",
  messagingSenderId: "817451620970",
  appId: "1:817451620970:web:a52163f475005a65608813",
};

export const firebaseLogin = {
  username: "plan9framebyframe@gmail.com",
  password: "Antel0pe2",
};
